//jsxhook

import { Country, currencyForCountry } from "@sixty-six-north/i18n"
import {
  BCorpLogo,
  ButtonVariants,
  Column,
  FormVariants,
  HeadingRegularVariants,
  ifBrowser,
  Label2,
  Label3,
  LinkVariants,
  Row,
  Select,
  StandardXAxisPadding,
  TechnicalParagraph,
  Button
} from "@sixty-six-north/ui-system"
import { setCookie } from "nookies"
import { PageTypeContext } from "pages/[...params]"
import { PrismicDocumentLink } from "prismic/slices/PrismicLink"
import React, { useContext, useEffect, useState, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { countryToStore } from "../cart/I18NStores"
import { onDynamicCategory } from "../filters/TagFilterHooks"

import { PageType } from "../routing/PageProps"
import { Box, Flex, ThemeUIStyleObject } from "theme-ui"
import { useCartService } from "../cart/CartServiceHook"
import { CartState } from "../cart/CartState"
import {
  useCurrentCategory,
  useTopLevelCategories
} from "../category/CategoryHooks"
import { useConfig } from "../Config"
import {
  COUNTRY_COOKIE_NAME,
  CURRENCY_COOKIE_NAME,
  CURRENT_STORE_COOKIE_NAME,
  LANGUAGE_COOKIE_NAME
} from "../i18n/Cookies"
import { countryToPath } from "../i18n/Country"
import { languageForCountry } from "../i18n/LanguageResolver"
import { REGION_PATH_COUNTRIES } from "../i18n/Region"
import { RegionState } from "../i18n/RegionState"
import { usePreferredLanguage, useStoreContext } from "../i18n/StoreHooks"
import { DomainCategory } from "../product/models/DomainCategory"
import { useRouter } from "../routing/useRouter"
import { persistor, useAppDispatch } from "../State"
import logger from "../utils/logger"
import Link from "./Link"
import { useCategoryLink } from "./Links"

interface Props {
  categories: DomainCategory[]
}

const ShippingSection: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        color: "white.0",
        textAlign: "left",
        pr: 24,
        mb: [16, null, 0, 0]
      }}
    >
      {children}
    </Box>
  )
}

const SubHeading: React.FC<{
  children?: ReactNode
  sx?: ThemeUIStyleObject
}> = ({ children, ...props }) => {
  return (
    <Box>
      <Label2
        sx={{
          marginBottom: 8,
          color: "white.0",
          "& > a": {
            color: "inherit",
            textDecoration: "none"
          }
        }}
        {...props}
      >
        {children}
      </Label2>
    </Box>
  )
}

const LinkSectionHeader: React.FC<{
  children?: ReactNode
  sx?: ThemeUIStyleObject
}> = ({ children, ...props }) => {
  return (
    <Box>
      <Label2 sx={{ color: "grey.2", marginBottom: 12 }} {...props}>
        {children}
      </Label2>
    </Box>
  )
}

const HorizontalDivider: React.FC = () => {
  return (
    <Box
      sx={{
        height: 1,
        width: "100%",
        backgroundColor: "grey.1"
      }}
    />
  )
}

const SignUp: React.FC = () => {
  const { t } = useTranslation("footer")
  return (
    <PrismicDocumentLink
      documentId={"YJpRbREAACMA84JU"}
      sx={{ variant: `buttons.${ButtonVariants.outlineDark}` }}
    >
      {t("signUp", { defaultValue: "Sign up" })}
    </PrismicDocumentLink>
  )
}

const FindAStore: React.FC = () => {
  // TODO: Add store locator
  const { t } = useTranslation("footer")

  return (
    <PrismicDocumentLink
      documentId={"XvxA3xAAACYAfWLO"}
      sx={{ variant: `buttons.${ButtonVariants.outlineDark}` }}
    >
      {t("findAStore", { defaultValue: "findAStore" })}
    </PrismicDocumentLink>
  )
}

const SignUpColumn: React.FC = () => {
  const { t } = useTranslation("footer")
  return (
    <Column>
      <SubHeading sx={{ color: "white.0" }}>
        {t("createAccountPrompt", { defaultValue: "PÓSTUR" })}
      </SubHeading>

      <Box sx={{ mb: 24 }}>
        <TechnicalParagraph sx={{ color: "grey.2" }}>
          {t("accountBenefits", {
            defaultValue:
              "Sign up to the PÓSTUR to hear about the latest stories and products from 66ºNorth"
          })}
        </TechnicalParagraph>
      </Box>
      <Box sx={{ marginBottom: [56, 56, 56, 64] }}>
        <SignUp />
      </Box>
    </Column>
  )
}

const StoreLocator: React.FC = () => {
  // TODO: Create store locator
  const { t } = useTranslation("footer")
  return (
    <Column>
      <SubHeading>
        {t("storeLocator", { defaultValue: "Store Locator" })}
      </SubHeading>
      <Box sx={{ mb: 24 }}>
        <Label2 sx={{ color: "grey.2" }}>
          {t("visitingIcelandOrDenmarkOrUk", {
            defaultValue:
              "Visiting Iceland, Denmark or the United Kingdom? Come and meet us!"
          })}
        </Label2>
      </Box>
      <Box marginBottom={56}>
        <FindAStore />
      </Box>
    </Column>
  )
}

const CategoryLink: React.FC<{ category: DomainCategory }> = ({ category }) => {
  const language = usePreferredLanguage()
  const link = useCategoryLink(category)

  return (
    <Box>
      <SubHeading>
        <Link {...link} sx={{ variant: `links.${LinkVariants.footer}` }}>
          {category.name[language]}
        </Link>
      </SubHeading>
    </Box>
  )
}

const Categories: React.FC<{ categories: DomainCategory[] }> = ({
  categories
}) => {
  const { t } = useTranslation("footer")
  return (
    <Column>
      <LinkSectionHeader>
        {t("66north", { defaultValue: "66ºNorth" })}
      </LinkSectionHeader>
      {categories.map(category => {
        return <CategoryLink key={category.key} category={category} />
      })}
    </Column>
  )
}

const GetInTouch: React.FC = () => {
  const { isShoppingInIceland } = useStoreContext()
  const { t } = useTranslation("footer")
  return (
    <Box marginBottom={56}>
      <LinkSectionHeader>
        {t("getInTouch", { defaultValue: "Get in touch" })}
      </LinkSectionHeader>
      <SubHeading>66north@66north.com</SubHeading>
      <SubHeading>+354 535 6600</SubHeading>
      <SubHeading>Midhraun 11, 210 Gardabaer</SubHeading>

      {isShoppingInIceland() && (
        <React.Fragment>
          <SubHeading>—</SubHeading>
          <PrismicDocumentLink
            documentId={"XyKwzRAAAB8AmsMG"}
            sx={{ variant: `links.${LinkVariants.footer}` }}
          >
            Viltu starfa hjá okkur?
          </PrismicDocumentLink>{" "}
        </React.Fragment>
      )}
    </Box>
  )
}

const HelpAndInformation = () => {
  const { t } = useTranslation("footer")
  return (
    <>
      <LinkSectionHeader sx={{ marginTop: [16, 16, 16, 0] }}>
        {t("helpAndInformation", { defaultValue: "Help & information" })}
      </LinkSectionHeader>
      <SubHeading>
        <PrismicDocumentLink
          documentId={"XweJlxQAACYAnfPn"}
          sx={{ variant: `links.${LinkVariants.footer}` }}
        >
          {t("contactUs", { defaultValue: "Contact Us" })}
        </PrismicDocumentLink>
      </SubHeading>
      <SubHeading>
        <PrismicDocumentLink
          documentId={"XweOUhQAACMAngkQ"}
          sx={{ variant: `links.${LinkVariants.footer}` }}
        >
          {t("shippingAndDelivery", { defaultValue: "Shipping and Delivery" })}
        </PrismicDocumentLink>
      </SubHeading>

      <SubHeading>
        <PrismicDocumentLink
          documentId={"XweULRQAACQAniLx"}
          sx={{ variant: `links.${LinkVariants.footer}` }}
        >
          {t("returns", { defaultValue: "Returns" })}
        </PrismicDocumentLink>
      </SubHeading>

      <SubHeading>
        <PrismicDocumentLink
          documentId={"YdV3KBAAACMA69Bz"}
          sx={{ variant: `links.${LinkVariants.footer}` }}
        >
          {t("accessibility", { defaultValue: "Accessibility" })}
        </PrismicDocumentLink>
      </SubHeading>

      <SubHeading>
        <PrismicDocumentLink
          documentId={"YJlV2hEAACUA7z8q"}
          sx={{ variant: `links.${LinkVariants.footer}` }}
        >
          {t("stockists", { defaultValue: "Stockists" })}
        </PrismicDocumentLink>
      </SubHeading>
    </>
  )
}

const SecondColumn: React.FC = () => {
  return (
    <Column sx={{ marginTop: [16, 16, 16, 0], marginLeft: [0, 0, 0, 32, 64] }}>
      <HelpAndInformation />
    </Column>
  )
}

const ThirdColumn: React.FC = () => {
  const { t } = useTranslation("footer")
  const links: Record<string, string> = {
    ["Instagram"]: "https://instagram.com/66north",
    ["Facebook"]: "https://facebook.com/66north",
    ["YouTube"]: "https://www.youtube.com/channel/UCCYU4l5SL99Kk5JUhJWBKmQ",
    ["TikTok"]:
      "https://www.tiktok.com/@66north?_t=8YCRZLY7hzB&_r=1&utm_source=66%C2%B0Nor%C3%B0ur+IS&utm_campaign=39d346173e-EMAIL_CAMPAIGN_2023_05_12_03_53_COPY_01&utm_medium=email&utm_term=0_-6f7b69fd4b-%5BLIST_EMAIL_ID%5D",
    ["LinkedIn"]: "https://www.linkedin.com/company/66-north",
    ["Strava"]: "https://www.strava.com/clubs/727844"
  }
  return (
    <Column sx={{ marginTop: [16, 16, 16, 0], marginLeft: [0, 0, 0, 32, 64] }}>
      <LinkSectionHeader>
        {t("socialNetworks", { defaultValue: "Social networks" })}
      </LinkSectionHeader>
      {Object.entries(links).map(([key, value], index) => (
        <SubHeading key={`footer-link-${index}`}>
          <a href={value} sx={{ variant: `links.${LinkVariants.footer}` }}>
            {key}
          </a>
        </SubHeading>
      ))}
    </Column>
  )
}

const ShippingProcessingAndWarranty: React.FC = () => {
  const store = useStoreContext()
  const freeShippingThreshold = store.freeShippingThreshold
  const { t } = useTranslation("footer")
  return (
    <Flex
      sx={{
        flexDirection: ["column", "column", "row"],
        py: [32, 32, 40],
        alignItems: ["flex-start", null, null, null, "center"],
        justifyContent: "space-around"
      }}
    >
      <ShippingSection>
        {t("footer:fullWarranty", "Full warranty against defects")}
      </ShippingSection>
      <ShippingSection>
        {t(
          "layout:freeShippingMessage",
          "Free Shipping on all orders {{ amount }}",
          { amount: freeShippingThreshold }
        )}
      </ShippingSection>
      <ShippingSection>
        {t("footer:deliveryIn1To4days", "Delivery in 1 - 4 working days")}
      </ShippingSection>
    </Flex>
  )
}

const DevToolsMenu: React.FC = () => {
  const [cartId, setCart] = useState<string | undefined>()
  const cartService = useCartService()

  useEffect(() => {
    cartService.refreshCart().then(it => {
      if (it) {
        setCart(it.id)
      }
    })
  }, [])

  return (
    <Column sx={{ marginTop: [16, 16, 16, 0], marginLeft: [0, 0, 0, 32, 64] }}>
      <LinkSectionHeader>Developer Tools (Debug only)</LinkSectionHeader>
      <Box mb={8} mt={8}>
        <form action={"#"}>
          <Button
            variant={"destructive"}
            fill={true}
            onClick={async () => {
              persistor.pause()
              await persistor
                .flush()
                .then(() => {
                  return persistor.purge()
                })
                .finally(() => persistor.persist())
            }}
          >
            Clear state
          </Button>
        </form>
      </Box>
      {cartId && (
        <Box mb={8} mt={8}>
          <form method={"POST"} action={"/api/test/cart"}>
            <input type={"hidden"} name={"cartId"} value={cartId} />
            <Button variant={"tertiary"} type={"submit"} fill={true}>
              Take cart to checkout
            </Button>
          </form>
        </Box>
      )}
    </Column>
  )
}

const FooterMenu: React.FC<Props> = ({ categories }) => {
  const config = useConfig()

  return (
    <>
      <Flex
        sx={{
          width: "100%",
          flexDirection: ["column", "column", "column", "row"],
          paddingTop: [32, 32, 32, 64]
        }}
      >
        {[SignUpColumn, StoreLocator, GetInTouch].map((Element, index) => (
          <Box
            key={index}
            sx={{
              width: ["inherit", "inherit", "inherit", 317],
              marginLeft: index > 0 ? [0, 0, 0, 64] : 0
            }}
          >
            <Element />
          </Box>
        ))}
      </Flex>
      <HorizontalDivider />
      <Flex
        sx={{
          justifyContent: "space-between",
          paddingTop: [32, 32, 32, 64],
          flexDirection: ["column", "column", "column", "row"]
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "column", "column", "row"],
            justifyContent: "space-between"
          }}
        >
          <Categories categories={categories} />
          <SecondColumn />
          <ThirdColumn />
          {config.isDevMode && <DevToolsMenu />}
        </Flex>
        <Box sx={{ marginTop: [32, 32, 32, 0] }}>
          <BCorpLogo mode="light" />
        </Box>
      </Flex>
    </>
  )
}

export const Footer: React.FC = () => {
  const { t } = useTranslation("layout")
  const topLevelCategories = useTopLevelCategories()
  const { country } = useStoreContext()
  const [currentCountry, setCurrentCountry] = useState(country)

  useEffect(() => {
    setCurrentCountry(country)
  }, [country])

  return (
    <Box as="footer">
      <Box
        sx={{
          bg: "black.1",
          borderTop: "solid",
          borderTopColor: "grey.1",
          borderTopWidth: "1px",
          pb: [32, 32, 32, 64]
        }}
      >
        <Row sx={{ px: StandardXAxisPadding }}>
          <Column sx={{ width: "100%" }}>
            <ShippingProcessingAndWarranty />
          </Column>
        </Row>
        <HorizontalDivider />
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "start",
            px: StandardXAxisPadding
          }}
        >
          <FooterMenu categories={topLevelCategories} />
        </Flex>
      </Box>
      <Flex
        sx={{
          px: StandardXAxisPadding,
          pb: 56,
          bg: "white.0",
          flexDirection: ["column", "column", "row-reverse"]
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "column", "row"],
            mb: 0,
            mt: 32,
            ml: [null, null, "auto"],
            minWidth: 256,
            "& > div": {
              flex: 1
            }
          }}
        >
          <RegionPicker id="country-selector" regionValue={currentCountry} />
        </Flex>
        <Column>
          <Box mb={12} mt={32}>
            <Box variant={`text.${HeadingRegularVariants.sixth}`}>
              {t("keepingIcelandWarm", {
                defaultValue: "Keeping Iceland warm since 1926"
              })}
            </Box>
          </Box>
          <Box mb={4}>
            <Label3 sx={{ color: "grey.1" }}>
              {t("copyright", {
                defaultValue: "© 66ºNorth Iceland, All rights reserved"
              })}
            </Label3>
          </Box>
          <Flex>
            <Label3>
              <PrismicDocumentLink
                documentId={"XweMLBQAACMAnf94"}
                sx={{ variant: `links.${LinkVariants.copyright}` }}
              >
                {t("privacyAndCookiePolicy", {
                  defaultValue: "Privacy & Cookie Policy"
                })}
              </PrismicDocumentLink>
            </Label3>

            <Label3 sx={{ ml: 16 }}>
              <a
                href="#"
                sx={{ variant: `links.${LinkVariants.copyright}` }}
                className="ch2-open-settings-btn"
                data-legalmonster="show-cookie-settings"
              >
                {t("layout:cookieSettings")}
              </a>
            </Label3>
          </Flex>
        </Column>
      </Flex>
    </Box>
  )
}

export const useCountrySelection = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const cartService = useCartService()
  const currentCountry = useStoreContext().country
  const pageType = useContext(PageTypeContext)
  const category = useCurrentCategory()
  const THIRTY_DAYS = 30 * 24 * 60 * 60
  const cookieOptions = {
    path: "/",
    maxAge: THIRTY_DAYS
  }

  return (selectedStore: Country) => {
    ifBrowser(() => {
      const currentPath = document.location.pathname
      const currency = currencyForCountry(selectedStore)
      const language = languageForCountry(selectedStore)

      setCookie(
        null,
        COUNTRY_COOKIE_NAME,
        selectedStore.toString(),
        cookieOptions
      )

      setCookie(null, CURRENT_STORE_COOKIE_NAME, selectedStore, {
        maxAge: THIRTY_DAYS,
        path: "/"
      })
      setCookie(null, CURRENCY_COOKIE_NAME, currency.toString(), cookieOptions)
      setCookie(null, LANGUAGE_COOKIE_NAME, language.toString(), cookieOptions)
      dispatch(CartState.actions.clearCart())
      cartService.setStore(countryToStore(selectedStore))
      cartService.refreshCart()
      dispatch(
        RegionState.actions.setRegion({
          country: selectedStore,
          currency,
          language
        })
      )

      const onNavigationError = err => {
        logger.warn(`Error navigating`, err)
      }

      const newCountryPath = countryToPath(selectedStore)
      const opts = { locale: newCountryPath }
      const search = window.location.search || ""
      const pathname = window.location.pathname || ""
      const regionalisedPath = `/${newCountryPath}`

      const replaceStoreInPath = (newStore: Country) => {
        const newStorePath = countryToPath(newStore)
        const currentStorePath = countryToPath(currentCountry)
        const newPath = pathname.replace(`/${currentStorePath}`, "")
        const { origin } = location
        window.location.href = `${origin}/${newStorePath}${newPath}${search}`
      }

      if (currentPath.includes("/checkout")) {
        const url = "/checkout"
        router.push(url, url, opts).catch(onNavigationError)
      } else if (
        currentPath.endsWith("/join") ||
        currentPath.endsWith("/search")
      ) {
        const url = `${regionalisedPath}/${pathname.replace(
          new RegExp(`\/${REGION_PATH_COUNTRIES.join("|")}\/`),
          "/"
        )}${search}`
        router.push(url, url, opts).catch(onNavigationError)
      } else if (!location.pathname.startsWith(regionalisedPath)) {
        switch (pageType) {
          case PageType.PRODUCT:
          case PageType.PRISMIC:
            replaceStoreInPath(selectedStore)
            break
          case PageType.CATEGORY: {
            const language: string = newCountryPath === "is" ? "is" : "en"
            const dynamicCategorySlug: string | undefined = onDynamicCategory(
              currentPath.replace("/", ""),
              (dynamicCategory, tag) => {
                return tag.slug[language]
              }
            )
            const baseCategorySlug = `${category?.slug?.[language]}`
            const slug = dynamicCategorySlug
              ? `${baseCategorySlug}-${dynamicCategorySlug}`
              : baseCategorySlug
            const newPath = `${regionalisedPath}/${slug}${search}`
            router.push(newPath, newPath, opts).catch(onNavigationError)
            break
          }
          default:
            const url = `${regionalisedPath}${search}`
            router
              .push(url, `${regionalisedPath}${search}`, opts)
              .catch(onNavigationError)
        }
      } else {
        router
          .push(
            `${regionalisedPath}${search}`,
            `${regionalisedPath}${search}`,
            opts
          )
          .catch(onNavigationError)
      }
    })
  }
}

export const RegionPicker: React.FC<{
  id: string
  regionValue: string | undefined
}> = ({ id, regionValue }) => {
  const { t } = useTranslation("footer")
  const setCountry = useCountrySelection()

  return (
    <Select
      id={id}
      name="country"
      value={regionValue}
      label={t("shippingDestination")}
      variant={FormVariants.select}
      onChange={event => setCountry(event.target.value as Country)}
    >
      {Object.keys(Country).map(key => {
        return (
          <option value={key} key={key}>
            {t(key)}
          </option>
        )
      })}
    </Select>
  )
}
