//jsxhook

import { zIndices } from "@sixty-six-north/ui-system/dist/cjs/theme/enums"
import dynamic from "next/dynamic"
import Head from "next/head"
import React, { useEffect, useState } from "react"

import { useRouter } from "routing/useRouter"
import { Box } from "theme-ui"
import { useIsDesktop, useIsMobile } from "utils/uiHelpers"
import { useLayout } from "../layout/LayoutState"
import { PageContent } from "./common/Layout/PageContent"
import { NavigationWrapper } from "./common/Navigation/NavigationWrapper"
import { Favicons } from "./Favicons"
import { Footer } from "./Footer"
import { GlobalMessageBar } from "./GlobalMessageBar"

interface LayoutProps {
  children: React.ReactNode
  minHeight?: string
}

const Search = dynamic(
  import("./common/Layout/SearchOverlay").then(it => it.Search)
)

const Layout: React.FC<LayoutProps> = ({ children, minHeight = "auto" }) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const [displayNoIndex, setDisplayNoIndex] = useState(false)
  const layout = useLayout()

  const router = useRouter()
  // turn off the menu transparency on each page change
  useEffect(() => {
    const handleRouteChange = () => {
      setTimeout(() => layout.disableMenuTransparency(), 200)
    }

    router.events.on("routeChangeStart", handleRouteChange)
    setDisplayNoIndex(!window.location.host.includes("66north.com"))

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, [])

  const { menuOpen, searchOpen } = layout.state

  return (
    <React.Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="p:domain_verify"
          content="b732caa1f2b5e31e080ccb87514fda4d"
        />
        {displayNoIndex && <meta name="robots" content="noindex, nofollow" />}
        <Favicons />
      </Head>
      {(searchOpen || (menuOpen && isMobile)) && <Search />}
      <Box
        sx={{
          width: "100%",
          overflowX: menuOpen && isMobile ? "hidden" : "unset"
        }}
      >
        <Box
          sx={{
            position: "relative",
            float: "left",
            width: "100%"
          }}
        >
          <GlobalMessageBar />
          <NavigationWrapper />
          {menuOpen && isDesktop && (
            <Box
              sx={{
                zIndex: zIndices.inPage,
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                variant: "layout.overlay"
              }}
            />
          )}
          <PageContent menuOpen={menuOpen}>
            <main sx={{ minHeight }}>{children}</main>
            <Footer />
          </PageContent>
        </Box>
      </Box>
    </React.Fragment>
  )
}
export default Layout
